import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        token: ''
    },
    getters: {
        token: (state) => state.token,
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
    }
})

export default store